import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import AddIcon from "../icon/AddIcon";
import sec1 from "../image/sec1.webp";
import sec2 from "../image/sec2.webp";
import sec2_1 from "../image/sec2-1.webp";
import sec2_2 from "../image/sec2-2.webp";
import sec4 from "../image/sec4.webp";
import sec4_1 from "../image/sec4-1.webp";
import sec4_2 from "../image/sec4-2.webp";
import sec4_3 from "../image/sec4-3.webp";
import sec4_4 from "../image/sec4-4.webp";
import sec4_5 from "../image/sec4-5.webp";
import sec5 from "../image/sec5.webp";
import Rating from "@material-ui/lab/Rating";
import sec6_people from "../image/sec6-people.jpg";
import sec6_1 from "../image/sec6-1.webp";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sec2: [
        {
          bg: sec2,
          title: "Organize",
        },
        {
          bg: sec2_1,
          title: "Declutter",
        },
        {
          bg: sec2_2,
          title: "Lifestyle",
        },
      ],
      sec4: [
        {
          bg: sec4,
          title: "Kitchen",
        },
        {
          bg: sec4_1,
          title: "Bedroom",
        },
        {
          bg: sec4_2,
          title: "Living",
        },
        {
          bg: sec4_3,
          title: "Bathroom",
        },
        {
          bg: sec4_4,
          title: "Storage",
        },
        {
          bg: sec4_5,
          title: "Kids",
        },
      ],
    };
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    const { sec2, sec4 } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <div className="left">
              <img src={sec1} alt="" />
            </div>
            <div className="right">
              <div className="title">
                Customizing My Dream Sofa With Interior Define
              </div>
              <p>
                If you’ve been looking at sofas for months and haven’t found
                exactly what you’re looking for, customizing with Interior
                Define may be your solution. Here’s how I designed the couch…
              </p>
            </div>
          </div>
          <div className="sec2">
            <div className="row">
              {sec2.map((item, index) => {
                return (
                  <div className="col" key={index}>
                    <div className="img">
                      <img src={item.bg} alt="" />
                      <div className="svg">{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec3">
            <div className="title">Get Organized-ish</div>
            <p>
              Get weekly blog post updates, organizing freebies, and my best
              secrets for a more functional home in your inbox.
            </p>
            <div className="row">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
              <input type="text" placeholder="Email Address" />
              <div className="btn">let's be friends !</div>
            </div>
          </div>
          <div className="sec4">
            <div className="title">Where to Start...</div>
            <div className="row">
              {sec4.map((item, index) => {
                return (
                  <div className="col" key={index}>
                    <div className="img">
                      <img src={item.bg} alt="" />
                    </div>
                    <div className="txt">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec5">
            <div className="contain">
              <div className="col">
                <h1>Behind The Blog</h1>
                <h2>Meet Lela</h2>
                <p>home & life organization expert</p>
                <p>
                  Hi! I'm Lela Burris and I'm a professional organizer turned
                  blogger. I help people get their homes and lives Organized-ish
                  by prioritizing functionality and systems over the hottest
                  trends and staged magazine photos. <br />
                  When I'm not reorganizing my kitchen for the fourth time this
                  year or vacuuming up fur tumbleweeds, you can find me in my
                  craft room with an explosion of miniatures on the floor or
                  hiding out in my bedroom with a hot cup of tea. <br />
                  Come for the organizing and cleaning tips, stay for my
                  adorable zoo of rescue pets. This is a place for progress, not
                  perfection. Because being Organized-ish is good enough. You
                  belong here.
                </p>
              </div>
              <div className="col">
                <img src={sec5} alt="" />
              </div>
            </div>
          </div>
          <div className="sec6">
            <div className="contain">
              <div className="left">
                <div className="row">
                  <div className="col">
                    <div className="img">
                      <img src={sec1} alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="title">
                      September 26, 2024 · In: Living Spaces, Reviews & Roundups
                    </div>
                    <h3>Customizing My Dream Sofa With Interior Define</h3>
                    <p>
                      If you’ve been looking at sofas for months and haven’t
                      found exactly what you’re looking for, customizing with
                      Interior Define may be your solution. Here’s how I
                      designed the couch…
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="img">
                      <img src={sec6_1} alt="" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="title">
                      August 20, 2024 · In: Lifestyle, Routines
                    </div>
                    <h3>
                      How I Built A Habit To Use Duolingo Every Day For A Year
                    </h3>
                    <p>
                      Learning a new language with Duolingo only works if you
                      practice consistently, but building that habit into your
                      daily routine is harder than it sounds. Here’s how to make
                      sure…
                    </p>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="title">Meet Lela Burris</div>
                <img src={sec6_people} alt="" />
                <p>
                  My name is Lela, (say it like LEE-luh) and I'm so glad you
                  stopped by my blog! Whether you need help organizing,
                  decluttering, tweaking daily routines, or you just think my
                  pets are cute, you'll find all kinds of inspo here.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
