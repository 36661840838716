import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import Facebook from "../../icon/Facebook";
import Instagram from "../../icon/Instagram";
import Pinterest from "../../icon/Pinterest";

import TikTok from "../../icon/TikTok";
import footImg from "../../image/footer.png";
import facebook from "../../image/facebook.svg";
import twitter from "../../image/twitter.svg";
import instagram from "../../image/instagram.svg";
import pinterest from "../../image/pinterest.svg";
import youtube from "../../image/youtube.svg";
import footer from "../../image/footer.svg";
import logo from "../../image/logo.webp";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "shipping":
        history.push("/Shipping");
        break;
      case "terms":
        history.push("/Terms");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footerBar">
          <div className="foot-top">
            <div className="col">
              Connect
              <div className="img">
                <div className="li">
                  <img src={instagram} alt="" />
                </div>
                <div className="li">
                  <img src={facebook} alt="" />
                </div>
                <div className="li">
                  <img src={twitter} alt="" />
                </div>
                <div className="li">
                  <img src={pinterest} alt="" />
                </div>
                <div className="li">
                  <img src={youtube} alt="" />
                </div>
              </div>
              <div className="img">
                <img src={logo} alt="" />
              </div>
              <h2>Get Email Updates</h2>
              <p>
                Sign up today for the latest news,
                <br /> promotions, and more from PetSafe® brand.
              </p>
            </div>
            <div className="col">
              {/* <li onClick={() => this.routePage("about")}>About us</li> */}
              <li onClick={() => this.routePage("contact")}>Contact us</li>
              <li onClick={() => this.routePage("privacy")}>Privacy Policy</li>
              <li onClick={() => this.routePage("terms")}>
                Terms and Conditions
              </li>
            </div>
          </div>
        </div>
        {/* <div className="foot-bottom">
          <img src={footer} alt="" />
          <div className="bot"></div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default FooterBar;
