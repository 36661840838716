import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/privacy.scss";
import sec6_people from "../image/sec6-people.jpg";

class Shipping extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="shipping-page">
          <div className="left">
            <div className="title">Privacy Policy</div>
            <p>
              <b>LelaBurris.com Privacy Policy</b>
            </p>
            <p>
              This privacy policy has been compiled to better serve those who
              are concerned with how their ‘Personally Identifiable Information’
              (PII) is being used online. PII, as described in US privacy law
              and information security, is information that can be used on its
              own or with other information to identify, contact, or locate a
              single person, or to identify an individual in context. Please
              read our privacy policy carefully to get a clear understanding of
              how we collect, use, protect or otherwise handle your Personally
              Identifiable Information in accordance with our website. <br />
              What personal information do we collect from the people that visit
              our blog, website or app? <br />
              When ordering or registering on our site, as appropriate, you may
              be asked to enter your name, email address or other details to
              help you with your experience.
              <br />
              When do we collect information? <br />
              We collect information from you when you register on our site,
              place an order, subscribe to a newsletter, respond to a survey,
              fill out a form or enter information on our site.
              <br />
              How do we use your information?
              <br />
              We may use the information we collect from you when you register,
              make a purchase, sign up for our newsletter, respond to a survey
              or marketing communication, surf the website, or use certain other
              site features in the following ways: <br />
              • To personalize your experience and to allow us to deliver the
              type of content and product offerings in which you are most
              interested. <br />
              • To improve our website in order to better serve you. <br />
              • To allow us to better service you in responding to your customer
              service requests. <br />
              • To administer a contest, promotion, survey or other site
              feature. <br />
              • To quickly process your transactions. <br />
              • To ask for ratings and reviews of services or products <br />
              • To follow up with them after correspondence (live chat, email or
              phone inquiries) <br />
              How do we protect your information? <br />
              Our website is scanned on a regular basis for security holes and
              known vulnerabilities in order to make your visit to our site as
              safe as possible. <br />
              We use regular Malware Scanning.
            </p>
            <p>
              Your personal information is contained behind secured networks and
              is only accessible by a limited number of persons who have special
              access rights to such systems, and are required to keep the
              information confidential. In addition, all sensitive/credit
              information you supply is encrypted via Secure Socket Layer (SSL)
              technology. <br /> We implement a variety of security measures
              when a user places an order or enters, submits, or accesses their
              information to maintain the safety of your personal information.
              All transactions are processed through a gateway provider and are
              not stored or processed on our servers. <br />
              Do we use ‘cookies’? <br />
              Yes. Cookies are small files that a site or its service provider
              transfers to your computer’s hard drive through your Web browser
              (if you allow) that enables the site’s or service provider’s
              systems to recognize your browser and capture and remember certain
              information. For instance, we use cookies to help us remember and
              process the items in your shopping cart. They are also used to
              help us understand your preferences based on previous or current
              site activity, which enables us to provide you with improved
              services. We also use cookies to help us compile aggregate data
              about site traffic and site interaction so that we can offer
              better site experiences and tools in the future. <br />
              We use cookies to: <br />
              • Help remember and process the items in the shopping cart. <br />
              • Understand and save user’s preferences for future visits. <br />
              • Keep track of advertisements. <br />
              • Compile aggregate data about site traffic and site interactions
              in order to offer better site experiences and tools in the future.
              We may also use trusted third-party services that track this
              information on our behalf. <br />
              You can choose to have your computer warn you each time a cookie
              is being sent, or you can choose to turn off all cookies. You do
              this through your browser settings. Since each browser is a little
              different, look at your browser’s Help Menu to learn the correct
              way to modify your cookies. <br />
              If users disable cookies in their browser: <br />
              If you turn cookies off, Some of the features that make your site
              experience more efficient may not function properly. <br />
              Third-party Disclosure
              <br />
              We do not sell, trade, or otherwise transfer to outside parties
              your Personally Identifiable Information unless we provide users
              with advance notice. This does not include website hosting
              partners and other parties who assist us in operating our website,
              conducting our business, or serving our users, so long as those
              parties agree to keep this information confidential. We may also
              release information when it’s release is appropriate to comply
              with the law, enforce our site policies, or protect ours or
              others’ rights, property or safety. However, non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses. <br />
            </p>
            <p>
              Third-party links <br />
              Occasionally, at our discretion, we may include or offer
              third-party products or services on our website. These third-party
              sites have separate and independent privacy policies. We therefore
              have no responsibility or liability for the content and activities
              of these linked sites. Nonetheless, we seek to protect the
              integrity of our site and welcome any feedback about these sites.{" "}
              <br /> here
            </p>
            <p>
              California Online Privacy Protection Act <br /> CalOPPA is the
              first state law in the nation to require commercial websites and
              online services to post a privacy policy. The law’s reach
              stretches well beyond California to require any person or company
              in the United States (and conceivably the world) that operates
              websites collecting Personally Identifiable Information from
              California consumers to post a conspicuous privacy policy on its
              website stating exactly the information being collected and those
              individuals or companies with whom it is being shared. – See more
              at:
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </p>
            <p></p>
          </div>
          <div className="right">
            <div className="title">Meet Lela Burris</div>
            <img src={sec6_people} alt="" />
            <p>
              My name is Lela, (say it like LEE-luh) and I'm so glad you stopped
              by my blog! Whether you need help organizing, decluttering,
              tweaking daily routines, or you just think my pets are cute,
              you'll find all kinds of inspo here.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Shipping;
