import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import sec6_people from "../image/sec6-people.jpg";
import contact from "../image/contact.webp";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="left">
            <div className="title">Contact</div>
            <p>
              Something you’d like to see? Have a question about a product or
              service? Curious how much denim I’m wearing today? You can contact
              me anytime with anything you’d like. We’re friends. And friends
              keep in touch. I’d love to hear from you! And who knows, your Q’s
              and comments could become our next big project together!
            </p>
            <p>
              Forms not your thing? It’s cool. You can also hit me up on
              Instagram. Or send a llama with a note tied to its collar,
              whatever’s your jam. Just be aware that I’ll probably keep the
              llama. Fair warning.
            </p>
            <p>
              And hey, before you type your question, head over to my FAQ page
              to see if I’ve already answered it.
            </p>
            <p>
              Media and Press Inquiries, please visit this page before
              contacting.
            </p>
            <img src={contact} alt="" />
            <p>Name(required)</p>
            <input type="text" />
            <p>Email(required)</p>
            <input type="text" />
            <p>Website</p>
            <input type="text" />
            <p>Comment(required)</p>
            <textarea name="" id=""></textarea>
            <div className="btn">SUBMIT</div>
          </div>
          <div className="right">
            <div className="title">Meet Lela Burris</div>
            <img src={sec6_people} alt="" />
            <p>
              My name is Lela, (say it like LEE-luh) and I'm so glad you stopped
              by my blog! Whether you need help organizing, decluttering,
              tweaking daily routines, or you just think my pets are cute,
              you'll find all kinds of inspo here.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
